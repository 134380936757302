<script>
import { defineComponent } from 'vue';
import ModuleNavigationBar from '@/components/Modules/ModuleNavigationBar.vue';
import PanelResizable from '@/components/Project/PanelResizable.vue';
import AntInput from '@/components/AntInput.vue';
import { mapGetters } from 'vuex';
import {
  closeTask,
  downloadTaskAppendix,
  getTasks,
} from '@/services/api/task.api';
import { getTables } from '@/services/api/table.api';
import { getTableQueryGraph } from '@/services/api/table-query.api';
import { updateRecord } from '@/services/api/record.api';
import QrScannerDialog from '@/components/Base/QrScannerDialog.vue';

export default defineComponent({
  name: 'DHMEElementValidation',
  components: {
    QrScannerDialog,
    AntInput,
    PanelResizable,
    ModuleNavigationBar,
  },
  data: () => {
    return {
      selectedProject: null,
      selectedModuleIndex: null,
      selectedElement: null,
      projectModules: null,
      projectTables: null,
      tasksLoading: false,
      drawingPreview: null,
      noDrawing: false,
      moduleElements: [],
      elementScanner: false,
      markAsCompleteDialog: false,
    };
  },
  computed: {
    ...mapGetters(['projects', 'isDaiwaLicense']),
    selectedModule() {
      if (this.selectedModuleIndex !== null) {
        return this.projectModules[this.selectedModuleIndex];
      }
      return null;
    },
  },
  watch: {
    selectedProject: async function (value) {
      this.tasksLoading = true;
      this.projectTables = await getTables(value.id);
      this.projectModules = await getTasks(
        {
          'filter[license]': value.license,
        },
        {
          advanced_filters: [
            {
              column: 'status',
              operator: '=',
              values: ['open'],
            },
            {
              column: 'project',
              operator: '=',
              values: [value.id],
            },
            {
              column: 'type',
              operator: '=',
              values: ['dhme-module-assembly'],
            },
          ],
        }
      );
      this.tasksLoading = false;
    },
    selectedModule: {
      deep: true,
      async handler(value) {
        if (value) {
          this.moduleElements = [];
          this.drawingPreview = null;
          let drawing = value.appendixes.find(
            (appendix) => appendix.name === `2D_${value.sbscode.code}`
          );
          if (!drawing) {
            this.noDrawing = true;
            this.$store.commit('showNotification', {
              content: '2D drawing not found for this module',
              color: 'info',
            });
          } else {
            let file = await downloadTaskAppendix(value.id, drawing.id);
            this.noDrawing = false;

            this.drawingPreview = `data:${file.mimetype};base64, ${file.file}`;
          }

          let elementsTable = this.projectTables.find(
            (item) => item.name === 'CFFA_DHME_ELEMENTS'
          );

          let result = await getTableQueryGraph({
            projectId: this.selectedProject.id,
            body: {
              tables: [
                {
                  tableId: elementsTable.id,
                  as: 'elements',
                  columns: [
                    {
                      name: 'element_id',
                      as: 'element_id',
                    },
                    {
                      name: 'module_id',
                      as: 'module_id',
                      conditions: [
                        {
                          operator: '=',
                          value: value.sbscode.code,
                        },
                      ],
                    },
                    {
                      name: 'element_type',
                      as: 'element_type',
                    },
                    {
                      name: 'production_id',
                      as: 'production_id',
                    },
                    {
                      name: 'element_position',
                      as: 'element_position',
                    },
                    {
                      name: 'element_category',
                      as: 'element_category',
                    },
                  ],
                },
              ],
            },
          });

          console.log(result);

          this.moduleElements = result.elements.records;
        }
      },
    },
  },
  methods: {
    elementDecoder(value) {
      return JSON.parse(value);
    },
    async scannedElement(data) {
      if (data.element_type !== this.selectedElement.element_type) {
        this.$store.commit('showNotification', {
          content: `Incorrect element scanned expected type '${this.selectedElement.element_type} and scanned '${data.element_type}'`,
          color: 'warning',
        });
        return;
      }

      if (!data.production_id) {
        this.$store.commit('showNotification', {
          content: `QR code does not contain a valid production_id value`,
          color: 'warning',
        });
      }

      let elementsTable = this.projectTables.find(
        (item) => item.name === 'CFFA_DHME_ELEMENTS'
      );

      try {
        let record = await updateRecord(this.selectedElement.id, {
          project: {
            id: this.selectedProject.id,
          },
          table: {
            id: elementsTable.id,
          },
          record: {
            production_id: data.production_id,
          },
        });
        Object.assign(
          this.moduleElements.find((item) => item.id === record.id),
          record
        );
      } catch (e) {
        this.$store.commit('showNotification', {
          content: `Something went wrong while linking the production id, please contact an administrator`,
          color: 'error',
        });
      }
    },
    validateElement(element) {
      this.selectedElement = element;
      this.elementScanner = true;
    },
    async closeTask() {
      let task = await closeTask(this.selectedModule.id);
      this.projectModules = this.projectModules.filter(
        (item) => item.id !== task.id
      );
      this.selectedModuleIndex = null;
      this.markAsCompleteDialog = false;
    },
  },
});
</script>

<template>
  <div class="d-flex flex-column">
    <module-navigation-bar title="Element validation">
      <template #module-nav-logo>
        <img
          v-if="isDaiwaLicense"
          alt=""
          height="40px"
          src="https://www.daiwahousemodular.eu/wp-content/uploads/daiwa_logo.svg"
        />
      </template>
    </module-navigation-bar>
    <div class="flex-grow-1 d-flex flex-scroll-height">
      <panel-resizable
        :collapsible="false"
        :default-width="300"
        :max-width="400"
        :min-width="100"
        side="left"
      >
        <div class="flex-grow-1 py-2 d-flex flex-column">
          <ant-input is-optional label="Select project" top-margin="mt-0 px-2">
            <template #input-field>
              <v-select
                v-model="selectedProject"
                :items="projects"
                dense
                filled
                hide-details
                item-text="name"
                placeholder="project"
                return-object
                single-line
              />
            </template>
          </ant-input>

          <v-progress-linear
            v-if="tasksLoading"
            class="my-2"
            color="primary"
            height="5px"
            indeterminate
          />
          <div
            v-if="selectedProject"
            class="flex-grow-1 overflow-y-auto flex-scroll-height"
          >
            <v-list shaped>
              <v-subheader>Modules</v-subheader>
              <v-list-item-group
                v-if="projectModules?.length > 0"
                v-model="selectedModuleIndex"
                color="primary"
              >
                <v-list-item v-for="(item, i) in projectModules" :key="i">
                  <v-list-item-icon v-if="item.status === 'closed'">
                    <v-icon color="success">mdi-check-circle</v-icon>
                  </v-list-item-icon>
                  <v-list-item-content>
                    <v-list-item-title
                      >{{ item.sbscode.code }}
                    </v-list-item-title>
                  </v-list-item-content>
                </v-list-item>
              </v-list-item-group>
              <div
                v-else-if="!tasksLoading"
                class="font-italic fs-10 d-flex align-center justify-center"
              >
                no modules found for this project
              </div>
            </v-list>
          </div>
        </div>
      </panel-resizable>
      <div v-if="selectedModule" class="flex-grow-1 d-flex">
        <div class="flex-grow-1 d-flex">
          <iframe
            v-if="!noDrawing"
            :src="drawingPreview"
            height="100%"
            type="application/pdf"
            width="100%"
          />
          <div
            v-else
            class="flex-grow-1 d-flex align-center justify-center font-italic"
          >
            No 2D drawing present for this module
          </div>
        </div>
        <div
          class="flex-grow-1 d-flex flex-column ant-border-left"
          style="max-width: 800px"
        >
          <div class="d-flex pa-5">
            <v-spacer />
            <v-btn
              :disabled="selectedModule.status !== 'open'"
              class="primary"
              elevation="0"
              @click="markAsCompleteDialog = true"
              >Mark module as complete
            </v-btn>
          </div>
          <div class="flex-grow-1 px-15 py-5">
            <div class="mb-2 d-flex">
              Module elements
              <v-spacer />
              {{
                moduleElements.filter((item) => item.production_id).length
              }}/{{ moduleElements.length }}
            </div>
            <div
              v-for="element in moduleElements"
              :key="element.id"
              class="ant-glass-background mb-2 d-flex overflow-hidden"
            >
              <div class="d-flex flex-grow-1">
                <div
                  class="d-flex align-center justify-center ant-border-right"
                  style="width: 150px"
                >
                  {{ element.element_category }}
                </div>
                <div class="pl-5 d-flex align-center justify-center">
                  {{ element.element_type }}
                </div>
                <v-spacer />
                <div
                  class="font-italic d-flex align-center justify-center mx-5"
                >
                  {{ element.element_position }}
                </div>
              </div>
              <div
                class="ant-border-left d-flex align-center justify-center"
                style="width: 70px; height: 40px"
              >
                <v-btn
                  v-if="!element.production_id"
                  :disabled="element.production_id"
                  elevation="0"
                  tile
                  @click="validateElement(element)"
                >
                  <v-icon>mdi-qrcode-scan</v-icon>
                </v-btn>
                <v-icon v-else color="success">mdi-check-circle</v-icon>
              </div>
            </div>
          </div>
          <qr-scanner-dialog
            v-model="elementScanner"
            title="Scan Element"
            :decode-parser="elementDecoder"
            @decoded="scannedElement"
            @close="
              elementScanner = false;
              selectedElement = null;
            "
          />

          <v-dialog
            v-model="markAsCompleteDialog"
            max-width="600px"
            @click:outside="markAsCompleteDialog = false"
            @keydown.esc="markAsCompleteDialog = false"
          >
            <v-card>
              <v-card-title class="justify-center text-uppercase headline">
                Mark module '{{ selectedModule.sbscode.code }}' as complete
              </v-card-title>
              <v-divider />
              <div class="px-10 py-5">
                Are you sure you want to mark module
                {{ selectedModule.sbscode.code }} as complete?<br /><br />

                You have validated
                {{
                  moduleElements.filter((item) => item.production_id).length
                }}/{{ moduleElements.length }} elements
              </div>
              <v-card-actions class="ant-dialog-actions-bg ant-border-top">
                <v-spacer />
                <v-btn
                  color="error"
                  small
                  text
                  @click="markAsCompleteDialog = false"
                >
                  Cancel
                </v-btn>
                <v-btn color="primary" elevation="0" small @click="closeTask">
                  Save
                </v-btn>
              </v-card-actions>
            </v-card>
          </v-dialog>
        </div>
      </div>
    </div>
  </div>
</template>

<style lang="scss" scoped></style>
